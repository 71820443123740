import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { ValidatorKeyEnum } from "../../../enums/validate";
import { ToastEnum, ToastStatusEnum, useToast } from "../../../hooks/toast";
import { ADMIN_CONFIG_TITLES, CONFIG_TITLES, IAssetConfigs, IGlobalAssetConfigs } from "../../../interfaces/settings";
import {
  FormError,
  FormInput,
  FormRow,
  SettingTitle,
  SettingsForm,
  SettingsStatWrapper,
  SettingsTableWrapper,
  SubmitButton,
} from "../style";

interface ISettingsTableProps {
  setting?: Partial<IGlobalAssetConfigs>;
  updateSettings: (payload: Partial<IGlobalAssetConfigs>) => void;
  isGlobal?: boolean;
}

export function SettingsTable({ setting, updateSettings, isGlobal = false }: ISettingsTableProps) {
  const { addToast } = useToast();
  const [isEdit, setEdit] = useState<boolean>(false);
  const [isDisabled, setDisabled] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    values: setting,
  });

  const configTitles = useMemo(
    () => (
      <>
        {Object.keys(CONFIG_TITLES).map((title, i) => (
          <div key={i}>
            <span>{CONFIG_TITLES[title as keyof IAssetConfigs]}</span>
            <div>{setting?.[title as keyof IAssetConfigs]}</div>
          </div>
        ))}
        {isGlobal
          ? Object.keys(ADMIN_CONFIG_TITLES).map((title, i) => (
            <div key={i}>
              <span>{ADMIN_CONFIG_TITLES[title as keyof IGlobalAssetConfigs]}</span>
              <div>{setting?.[title as keyof IGlobalAssetConfigs]}</div>
            </div>
            ))
          : null}
      </>
    ),
    [isGlobal, setting]
  );

  const onUpdateSettings = useCallback(
    async (payload: Partial<IGlobalAssetConfigs>) => {
      if (setting) {
        const difference: Partial<IGlobalAssetConfigs> = {
          account: payload.account,
          asset: payload.asset,
          instrument_type: payload.instrument_type,
        };

        // Loop through keys of initial configs
        Object.keys(setting).forEach((key: any) => {
          const field = key as keyof IGlobalAssetConfigs;

          // If there is a difference in values, assign into the difference object
          if (Object.keys(payload).includes(field) && setting[field] !== payload[field]) {
            difference[field] = payload[field];
          }
        });

        // Check that there are updated properties on the difference object
        if (Object.keys(difference).length > 3) {
          await updateSettings(difference);
          setEdit(false);
        } else {
          addToast(
            {
              type: ToastEnum.SIMPLE,
              header: "Account Settings Update Failed",
              subheader: "There are no updated fields",
              status: ToastStatusEnum.ERROR,
            },
            4000
          );
        }
      }
    },
    [addToast, setting, updateSettings]
  );

  useEffect(() => {
    if (Object.keys(errors).length > 0) return setDisabled(true);

    if (setting) {
      const formListener = watch((formData) => {
        let differenceCount = 0;

        Object.keys(setting).forEach((key: any) => {
          const field = key as keyof IAssetConfigs;

          // If there is a difference in values, +1
          if (Object.keys(formData).includes(field) && setting[field] !== formData[field]) {
            differenceCount += 1;
          }
        });

        return setDisabled(differenceCount < 1);
      });

      return () => {
        formListener.unsubscribe();
      };
    }

    return setDisabled(false);
  }, [errors, isDisabled, setting, watch]);

  useEffect(() => {
    if (isEdit === false) {
      reset();
    }
  }, [isEdit, reset, setting]);

  const errorContent = useCallback(
    (field: keyof IAssetConfigs) => (
      <>
        {/* All fields except fees must be greater than 0 */}
        {(errors?.[field] as FieldError)?.type === ValidatorKeyEnum.GtZero && (
          <FormError>{CONFIG_TITLES[field]} must be greater than 0</FormError>
        )}
        {(errors?.[field] as FieldError)?.type === ValidatorKeyEnum.GtNegOne && (
          <FormError>{CONFIG_TITLES[field]} must be greater than -1</FormError>
        )}
        {(errors?.[field] as FieldError)?.type === ValidatorKeyEnum.GteZero && (
          <FormError>{CONFIG_TITLES[field]} must be greater than / equal to 0</FormError>
        )}
        {/* Margin & fees must be lesser than 0 */}
        {(errors?.[field] as FieldError)?.type === ValidatorKeyEnum.LteOne && (
          <FormError>{CONFIG_TITLES[field]} must be lesser or equal to 1</FormError>
        )}
      </>
    ),
    [errors]
  );

  const globalErrorContent = useCallback(
    (field: keyof IGlobalAssetConfigs) => (
      <>
        {/* All fields except fees must be greater than 0 */}
        {(errors?.[field] as FieldError)?.type === ValidatorKeyEnum.GtZero && (
          <FormError>{ADMIN_CONFIG_TITLES[field]} must be greater than 0</FormError>
        )}
        {(errors?.[field] as FieldError)?.type === ValidatorKeyEnum.GteZero && (
          <FormError>{ADMIN_CONFIG_TITLES[field]} must be greater than / equal to 0</FormError>
        )}
        {/* Margin & fees must be lesser than 0 */}
        {(errors?.[field] as FieldError)?.type === ValidatorKeyEnum.LteOne && (
          <FormError>{ADMIN_CONFIG_TITLES[field]} must be lesser or equal to 1</FormError>
        )}
      </>
    ),
    [errors]
  );

  if (!setting) return null;

  return (
    <SettingsTableWrapper>
      {isEdit ? (
        <SettingsForm onSubmit={handleSubmit(onUpdateSettings)}>
          <SettingTitle>
            <div>
              <p>
                {setting.asset} {setting.instrument_type}
              </p>
            </div>
            <div>
              <SubmitButton type="submit" disabled={isDisabled}>
                Save
              </SubmitButton>
              <button
                type="button"
                onClick={() => {
                  reset();
                  setDisabled(false);
                  setEdit(false);
                }}
              >
                Cancel
              </button>
            </div>
          </SettingTitle>

          <FormRow>
            <span>{CONFIG_TITLES.initial_margin}</span>
            <div>
              <FormInput
                hasErrors={Object.keys(errors).includes("initial_margin")}
                type="number"
                {...register("initial_margin", {
                  validate: {
                    [ValidatorKeyEnum.GtZero]: (v) => Number(v) > 0,
                    [ValidatorKeyEnum.LteOne]: (v) => Number(v) <= 1,
                  },
                })}
                onChange={(e) => {
                  setValue("initial_margin", String(e.target.value));
                  trigger("initial_margin");
                }}
              />
              {errorContent("initial_margin")}
            </div>
          </FormRow>
          <FormRow>
            <span>{CONFIG_TITLES.maintenance_margin}</span>
            <div>
              <FormInput
                hasErrors={Object.keys(errors).includes("maintenance_margin")}
                type="number"
                {...register("maintenance_margin", {
                  validate: {
                    [ValidatorKeyEnum.GtZero]: (v) => Number(v) > 0,
                    [ValidatorKeyEnum.LteOne]: (v) => Number(v) <= 1,
                  },
                })}
                onChange={(e) => {
                  setValue("maintenance_margin", String(e.target.value));
                  trigger("maintenance_margin");
                }}
              />
              {errorContent("maintenance_margin")}
            </div>
          </FormRow>
          <FormRow>
            <span>{CONFIG_TITLES.portfolio_initial_margin}</span>
            <div>
              <FormInput
                hasErrors={Object.keys(errors).includes("portfolio_initial_margin")}
                type="number"
                {...register("portfolio_initial_margin", {
                  validate: {
                    [ValidatorKeyEnum.GtZero]: (v) => Number(v) > 0,
                    [ValidatorKeyEnum.LteOne]: (v) => Number(v) <= 1,
                  },
                })}
                onChange={(e) => {
                  setValue("portfolio_initial_margin", String(e.target.value));
                  trigger("portfolio_initial_margin");
                }}
              />
              {errorContent("portfolio_initial_margin")}
            </div>
          </FormRow>
          <FormRow>
            <span>{CONFIG_TITLES.portfolio_maintenance_margin}</span>
            <div>
              <FormInput
                hasErrors={Object.keys(errors).includes("portfolio_maintenance_margin")}
                type="number"
                {...register("portfolio_maintenance_margin", {
                  validate: {
                    [ValidatorKeyEnum.GtZero]: (v) => Number(v) > 0,
                    [ValidatorKeyEnum.LteOne]: (v) => Number(v) <= 1,
                  },
                })}
                onChange={(e) => {
                  setValue("portfolio_maintenance_margin", String(e.target.value));
                  trigger("portfolio_maintenance_margin");
                }}
              />
              {errorContent("portfolio_maintenance_margin")}
            </div>
          </FormRow>
          <FormRow>
            <span>{CONFIG_TITLES.taker_fee}</span>
            <div>
              <FormInput
                hasErrors={Object.keys(errors).includes("taker_fee")}
                type="number"
                {...register("taker_fee", {
                  validate: {
                    [ValidatorKeyEnum.GteZero]: (v) => Number(v) >= 0,
                    [ValidatorKeyEnum.LteOne]: (v) => Number(v) <= 1,
                  },
                })}
                onChange={(e) => {
                  setValue("taker_fee", String(e.target.value));
                  trigger("taker_fee");
                }}
              />
              {errorContent("taker_fee")}
            </div>
          </FormRow>
          <FormRow>
            <span>{CONFIG_TITLES.maker_fee}</span>
            <div>
              <FormInput
                hasErrors={Object.keys(errors).includes("maker_fee")}
                type="number"
                {...register("maker_fee", {
                  validate: {
                    [ValidatorKeyEnum.GtNegOne]: (v) => Number(v) > -1,
                    [ValidatorKeyEnum.LteOne]: (v) => Number(v) <= 1,
                  },
                })}
                onChange={(e) => {
                  setValue("maker_fee", String(e.target.value));
                  trigger("maker_fee");
                }}
              />
              {errorContent("maker_fee")}
            </div>
          </FormRow>
          <FormRow>
            <span>{CONFIG_TITLES.premium_cap}</span>
            <div>
              <FormInput
                hasErrors={Object.keys(errors).includes("premium_cap")}
                type="number"
                {...register("premium_cap", {
                  validate: {
                    [ValidatorKeyEnum.GteZero]: (v) => Number(v) >= 0,
                    [ValidatorKeyEnum.LteOne]: (v) => Number(v) <= 1,
                  },
                })}
                onChange={(e) => {
                  setValue("premium_cap", String(e.target.value));
                  trigger("premium_cap");
                }}
              />
              {errorContent("premium_cap")}
            </div>
          </FormRow>
          <FormRow>
            <span>{CONFIG_TITLES.liquidation_fee}</span>
            <div>
              <FormInput
                hasErrors={Object.keys(errors).includes("liquidation_fee")}
                type="number"
                {...register("liquidation_fee", {
                  validate: {
                    [ValidatorKeyEnum.GteZero]: (v) => Number(v) >= 0,
                    [ValidatorKeyEnum.LteOne]: (v) => Number(v) <= 1,
                  },
                })}
                onChange={(e) => {
                  setValue("liquidation_fee", String(e.target.value));
                  trigger("liquidation_fee");
                }}
              />
              {errorContent("liquidation_fee")}
            </div>
          </FormRow>
          <FormRow>
            <span>{CONFIG_TITLES.liquidation_fee_cap}</span>
            <div>
              <FormInput
                hasErrors={Object.keys(errors).includes("liquidation_fee_cap")}
                type="number"
                {...register("liquidation_fee_cap", {
                  validate: {
                    [ValidatorKeyEnum.GteZero]: (v) => Number(v) >= 0,
                    [ValidatorKeyEnum.LteOne]: (v) => Number(v) <= 1,
                  },
                })}
                onChange={(e) => {
                  setValue("liquidation_fee_cap", String(e.target.value));
                  trigger("liquidation_fee_cap");
                }}
              />
              {errorContent("liquidation_fee_cap")}
            </div>
          </FormRow>
          {isGlobal ? (
            <>
              <FormRow>
                <span>{ADMIN_CONFIG_TITLES.price_step_size}</span>
                <div>
                  <FormInput
                    hasErrors={Object.keys(errors).includes("price_step_size")}
                    type="number"
                    {...register("price_step_size", {
                      validate: {
                        [ValidatorKeyEnum.GteZero]: (v) => Number(v) >= 0,
                      },
                    })}
                    onChange={(e) => {
                      setValue("price_step_size", String(e.target.value));
                      trigger("price_step_size");
                    }}
                  />
                  {globalErrorContent("price_step_size")}
                </div>
              </FormRow>
              <FormRow>
                <span>{ADMIN_CONFIG_TITLES.amount_step_size}</span>
                <div>
                  <FormInput
                    hasErrors={Object.keys(errors).includes("amount_step_size")}
                    type="number"
                    {...register("amount_step_size", {
                      validate: {
                        [ValidatorKeyEnum.GteZero]: (v) => Number(v) >= 0,
                      },
                    })}
                    onChange={(e) => {
                      setValue("amount_step_size", String(e.target.value));
                      trigger("amount_step_size");
                    }}
                  />
                  {globalErrorContent("amount_step_size")}
                </div>
              </FormRow>
              <FormRow>
                <span>{ADMIN_CONFIG_TITLES.min_amount}</span>
                <div>
                  <FormInput
                    hasErrors={Object.keys(errors).includes("min_amount")}
                    type="number"
                    {...register("min_amount", {
                      validate: {
                        [ValidatorKeyEnum.GteZero]: (v) => Number(v) >= 0,
                      },
                    })}
                    onChange={(e) => {
                      setValue("min_amount", String(e.target.value));
                      trigger("min_amount");
                    }}
                  />
                  {globalErrorContent("min_amount")}
                </div>
              </FormRow>
              <FormRow>
                <span>{ADMIN_CONFIG_TITLES.max_amount}</span>
                <div>
                  <FormInput
                    hasErrors={Object.keys(errors).includes("max_amount")}
                    type="number"
                    {...register("max_amount", {
                      validate: {
                        [ValidatorKeyEnum.GteZero]: (v) => Number(v) >= 0,
                      },
                    })}
                    onChange={(e) => {
                      setValue("max_amount", String(e.target.value));
                      trigger("max_amount");
                    }}
                  />
                  {globalErrorContent("max_amount")}
                </div>
              </FormRow>
              <FormRow>
                <span>{ADMIN_CONFIG_TITLES.max_notional}</span>
                <div>
                  <FormInput
                    hasErrors={Object.keys(errors).includes("max_notional")}
                    type="number"
                    {...register("max_notional", {
                      validate: {
                        [ValidatorKeyEnum.GteZero]: (v) => Number(v) >= 0,
                      },
                    })}
                    onChange={(e) => {
                      setValue("max_notional", String(e.target.value));
                      trigger("max_notional");
                    }}
                  />
                  {globalErrorContent("max_notional")}
                </div>
              </FormRow>
              <FormRow>
                <span>{ADMIN_CONFIG_TITLES.price_band}</span>
                <div>
                  <FormInput
                    hasErrors={Object.keys(errors).includes("price_band")}
                    type="number"
                    {...register("price_band", {
                      validate: {
                        [ValidatorKeyEnum.GteZero]: (v) => Number(v) >= 0,
                      },
                    })}
                    onChange={(e) => {
                      setValue("price_band", String(e.target.value));
                      trigger("price_band");
                    }}
                  />
                  {globalErrorContent("price_band")}
                </div>
              </FormRow>
              <FormRow>
                <span>{ADMIN_CONFIG_TITLES.price_band_index}</span>
                <div>
                  <FormInput
                    hasErrors={Object.keys(errors).includes("price_band_index")}
                    type="number"
                    {...register("price_band_index", {
                      validate: {
                        [ValidatorKeyEnum.GteZero]: (v) => Number(v) >= 0,
                      },
                    })}
                    onChange={(e) => {
                      setValue("price_band_index", String(e.target.value));
                      trigger("price_band_index");
                    }}
                  />
                  {globalErrorContent("price_band_index")}
                </div>
              </FormRow>
              <FormRow>
                <span>{ADMIN_CONFIG_TITLES.insurance_fund}</span>
                <div>
                  <FormInput
                    hasErrors={Object.keys(errors).includes("insurance_fund")}
                    type="string"
                    {...register("insurance_fund", {
                      validate: {},
                    })}
                    onChange={(e) => {}}
                  />
                  {globalErrorContent("insurance_fund")}
                </div>
              </FormRow>
              <FormRow>
                <span>{ADMIN_CONFIG_TITLES.price_band_index}</span>
                <div>
                  <FormInput
                    hasErrors={Object.keys(errors).includes("fair_impact_tolerance")}
                    type="number"
                    {...register("fair_impact_tolerance", {
                      validate: {
                        [ValidatorKeyEnum.GteZero]: (v) => Number(v) >= 0,
                      },
                    })}
                    onChange={(e) => {
                      setValue("fair_impact_tolerance", String(e.target.value));
                      trigger("fair_impact_tolerance");
                    }}
                  />
                  {globalErrorContent("fair_impact_tolerance")}
                </div>
              </FormRow>
              <FormRow>
                <span>{ADMIN_CONFIG_TITLES.liquidation_max_slippage}</span>
                <div>
                  <FormInput
                    hasErrors={Object.keys(errors).includes("liquidation_max_slippage")}
                    type="number"
                    {...register("liquidation_max_slippage", {
                      validate: {
                        [ValidatorKeyEnum.GteZero]: (v) => Number(v) >= 0,
                      },
                    })}
                    onChange={(e) => {
                      setValue("liquidation_max_slippage", String(e.target.value));
                      trigger("liquidation_max_slippage");
                    }}
                  />
                  {globalErrorContent("liquidation_max_slippage")}
                </div>
              </FormRow>
              <FormRow>
                <span>{ADMIN_CONFIG_TITLES.fair_impact_notional}</span>
                <div>
                  <FormInput
                    hasErrors={Object.keys(errors).includes("fair_impact_notional")}
                    type="number"
                    {...register("fair_impact_notional", {
                      validate: {
                        [ValidatorKeyEnum.GteZero]: (v) => Number(v) >= 0,
                      },
                    })}
                    onChange={(e) => {
                      setValue("fair_impact_notional", String(e.target.value));
                      trigger("fair_impact_notional");
                    }}
                  />
                  {globalErrorContent("fair_impact_notional")}
                </div>
              </FormRow>
              <FormRow>
                <span>{ADMIN_CONFIG_TITLES.max_open_interest}</span>
                <div>
                  <FormInput
                    hasErrors={Object.keys(errors).includes("max_open_interest")}
                    type="number"
                    {...register("max_open_interest", {
                      validate: {
                        [ValidatorKeyEnum.GteZero]: (v) => Number(v) >= 0,
                      },
                    })}
                    onChange={(e) => {
                      setValue("max_open_interest", String(e.target.value));
                      trigger("max_open_interest");
                    }}
                  />
                  {globalErrorContent("max_open_interest")}
                </div>
              </FormRow>
            </>
          ) : null}
        </SettingsForm>
      ) : (
        <>
          <SettingTitle>
            <div>
              <p>
                {setting.asset} {setting.instrument_type}
              </p>
            </div>
            <div>
              <SubmitButton type="button" onClick={() => setEdit(true)}>
                Edit
              </SubmitButton>
            </div>
          </SettingTitle>
          <SettingsStatWrapper>{configTitles}</SettingsStatWrapper>
        </>
      )}
    </SettingsTableWrapper>
  );
}
